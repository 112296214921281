import React from 'react';
import CardWrapper from '../../shared/CardWrapper.jsx';
import OverallLeaderboard from '../OverallLeaderboard.jsx';
import PreviousRound from '../PreviousRound.jsx';
import PlayerPaths from '../PlayerPaths.jsx';
import CurrentRoundTitle from '../CurrentRoundTitle.jsx';

function NewRoundAsSlave ({ gameState }) {
  const masterName = gameState.players[gameState.currentRound.masterPlayerId].name;

  return (
    <>
      <div className="md:w-full p-2">
        <CardWrapper>
          <CurrentRoundTitle gameState={gameState} />
          <h2 className="text-lg text-center">{masterName} is the Challenger.</h2>
        </CardWrapper>
      </div>

      <OverallLeaderboard gameState={gameState} />
      <PreviousRound gameState={gameState} />

      <PlayerPaths players={gameState.players} round={gameState.prevRound} />
    </>
  );
}

export default NewRoundAsSlave;
