import React from 'react';

function CurrentRoundTitle({ gameState }) {
  const roundNumber = gameState.totalRounds - gameState.roundsLeft;

  return (
    <h2 className="text-lg text-center uppercase">
      {roundNumber === gameState.totalRounds ? (
        'Final round'
      ) : (
        `Round ${roundNumber}/${gameState.totalRounds}`
      )}
    </h2>
  );
}

export default CurrentRoundTitle;
