import { customLocalStorage } from './customStorage.js';
import { LOCAL_STORAGE_TOKEN_KEY } from './constants.js';

export function saveToken(token) {
  customLocalStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
}

export function getToken() {
  return customLocalStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || '';
}
