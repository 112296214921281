import React, { useEffect, useState } from 'react';

import NewRoundForm from '../shared/NewRoundForm.jsx';
import PlayerPath from '../shared/PlayerPath.jsx';
import CardWrapper from '../shared/CardWrapper.jsx';
import RoundStartedCard from '../shared/RoundStartedCard.jsx';

const roundTimeChoices = {
  HALF_MINUTE: 30,
  ONE_MINUTE: 60,
  MINUTE_AND_HALF: 90,
  TWO_MINUTES: 120,
  TWO_MINUTES_AND_HALF: 150,
  THREE_MINUTES: 180
}

async function getPage(title) {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/find-article/${title}`);
  return await response.json();
}

async function followRedirects(page) {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/follow-redirects/${page.id}`);
  return await response.json();
}

function SingleplayerGame() {
  const [status, setStatus] = useState('NEW_ROUND');
  const [beginPage, setBeginPage] = useState(null);
  const [endPage, setEndPage] = useState(null);
  const [playerPath, setPlayerPath] = useState([]);
  const [secondsLeft, setSecondsLeft] = useState(0);
  const currentPage = playerPath.slice(-1)[0];

  const startRound = async ({ beginPage, endPage, roundTime }) => {
    setBeginPage(await getPage(beginPage));
    setEndPage(await getPage(endPage));
    setPlayerPath([]);
    setSecondsLeft(roundTimeChoices[roundTime]);
    setStatus('ROUND_STARTED');
  };

  useEffect(() => {
    let intervalId;

    if (status === 'ROUND_STARTED') {
      intervalId = setInterval(() => {
        setSecondsLeft(prev => prev - 1);

        if (secondsLeft <= 0) {
          setStatus('NEW_ROUND')
        }
      }, 1000)
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    }
  }, [status, secondsLeft]);

  const handleChangeCurrentPage = async (newPage) => {
    newPage = await followRedirects(newPage);
    if (newPage.id === endPage.id) {
      setStatus('NEW_ROUND');
    }

    setPlayerPath(prevPath => prevPath.concat([newPage]));
  };

  return (
    <div className="max-w-xl mx-auto space-y-6 pb-6">
      {status === 'NEW_ROUND' && (
        <CardWrapper>
          <h2 className="text-lg">New Round</h2>
          <NewRoundForm onSubmit={startRound} />
        </CardWrapper>
      )}

      {status === 'NEW_ROUND' && beginPage && (
        <CardWrapper>
          <div className="text-center">
            <h2 className="text-lg">
              {`Previous Round (${(currentPage && currentPage.id) === (endPage && endPage.id) ? `Win in ${playerPath.length} steps` : 'Loss'})`}
            </h2>
            <PlayerPath
              beginPage={beginPage}
              endPage={endPage}
              playerPath={playerPath}
            />
          </div>
        </CardWrapper>
      )}

      {status === 'ROUND_STARTED' && (
        <RoundStartedCard
          secondsLeft={secondsLeft}
          beginPage={beginPage}
          endPage={endPage}
          playerPath={playerPath}
          onChange={handleChangeCurrentPage}
        />
      )}
    </div>
  );
}

export default SingleplayerGame;
