import React from 'react';

function CardWrapper({ children }) {
  return (
    <div className="shadow-md bg-white p-6">
      {children}
    </div>
  );
}

export default CardWrapper;
