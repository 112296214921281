import React from 'react';

function PlayerPathMinimal({ beginPage, endPage, playerPath }) {
  const prevPage = playerPath[playerPath.length - 2];
  const currentPage = playerPath[playerPath.length - 1];

  return (
    <>
      {prevPage ? (
        <div>{prevPage.title}</div>
      ) : (
        <div className={currentPage ? '' : 'font-bold text-xl'}>{beginPage && beginPage.title}</div>
      )}
      {currentPage && (
        <>
          <div className="text-xl">↓</div>
          <div className="font-bold text-xl">{currentPage.title}</div>
        </>
      )}
      {(currentPage && currentPage.id) !== (endPage && endPage.id) && (
        <>
          <div className="text-xl">↓</div>
          <div>{endPage && endPage.title}</div>
        </>
      )}
    </>
  );
}

export default PlayerPathMinimal;
