import React from 'react';
import CardWrapper from '../shared/CardWrapper.jsx';
import OverallLeaderboard from './OverallLeaderboard.jsx';
import PreviousRound from './PreviousRound.jsx';
import PlayerPaths from './PlayerPaths.jsx';

function GameEnded ({ gameState }) {
  const players = Object.entries(gameState.players).map(([id, p]) => (
    { name: p.name, score: gameState.overallScoreboard[id] }
  ));
  const champion = players.reduce((p, champion) => {
    if (p.score > champion.score) return p;

    return champion;
  }, { score: 0 });

  return (
    <>
      <div className="md:w-full p-2 text-center">
        <CardWrapper>
          <h2 className="mb-0 text-xl">And the winner is... {champion.name}!</h2>
        </CardWrapper>
      </div>

      <OverallLeaderboard gameState={gameState} />
      <PreviousRound gameState={gameState} />

      <PlayerPaths players={gameState.players} round={gameState.prevRound} />
    </>
  );
}

export default GameEnded;
