import React from 'react';
import CardWrapper from '../shared/CardWrapper.jsx';

function Scoreboard({ title, players }) {
  const sortedPlayers = players.sort((p1, p2) => p2.score - p1.score);

  return (
    <CardWrapper>
      <h2 className="text-lg text-center uppercase">{title}</h2>

      <table className="w-full">
        <tbody>
        {sortedPlayers.map((p, index) => (
          <tr key={index}>
            <td className="pr-4">#{index + 1}</td>
            <td className="w-full pr-4">{p.name}</td>
            <td className="text-right">{p.score}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </CardWrapper>
  );
}

export default Scoreboard;
