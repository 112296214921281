import React from 'react';
import PlayerPath from '../shared/PlayerPath.jsx';
import CardWrapper from '../shared/CardWrapper.jsx';

function PlayerPaths({ players, round }) {
  if (!round) return null;

  const { beginPage, endPage, playerPaths, playerStats } = round;
  const sortedEntries = Object.entries(playerPaths).sort(([id1, path1], [id2, path2]) => {
    const stats1 = playerStats[id1];
    const stats2 = playerStats[id2];

    if (!stats1.finished && !stats2.finished) {
      return path2.length - path1.length;
    }

    if (stats1.finished && !stats2.finished) return -1;
    if (!stats1.finished && stats2.finished) return 1;

    return stats2.secondsLeft - stats1.secondsLeft;
  });

  return (
    <>
      {sortedEntries.map(([playerId, playerPath], index) => (
        <div key={index} className="md:w-1/2 p-2">
          <CardWrapper>
            <div className="text-center">
              <h2 className="text-lg">
                {players[playerId].name}
                {playerStats[playerId].finished ? ` (#${index + 1})` : ''}
              </h2>

              <PlayerPath
                beginPage={beginPage}
                endPage={endPage}
                playerPath={playerPath}
              />
            </div>
          </CardWrapper>
        </div>
      ))}
    </>
  );
}

export default PlayerPaths;
