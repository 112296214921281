import React, { useState } from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import SingleplayerGame from './singleplayer/SingleplayerGame';
import MultiplayerGame from './multiplayer/MultiplayerGame';
import Home from './Home';
import useSocketOn from './useSocketOn';
import ReconnectingAlert from './ReconnectingAlert.jsx';

function AppLayout () {
  const [myId, setMyId] = useState();
  useSocketOn('CONNECTION_WAS_SUCCESSFUL', (id, _token) => {
    setMyId(id);
  });

  return (
    <div className="max-w-4xl mx-auto h-full px-6">
      <div className="pt-2">
        <ReconnectingAlert />
      </div>

      <NavLink to="/" className="flex justify-center items-center text-2xl p-6">
        <span className="inline-block w-10 p-1">
          <svg fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
            <path d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
          </svg>
        </span>
        WikiRacer
      </NavLink>

      <Switch>
        <Route path="/singleplayer">
          <SingleplayerGame />
        </Route>
        <Route exact path="/:gameId">
          <MultiplayerGame myId={myId} />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </div>
  )
}

export default AppLayout;
