import React from 'react';
import CardWrapper from './CardWrapper.jsx';
import PlayerPathMinimal from './PlayerPathMinimal.jsx';
import Timer from './Timer.jsx';
import ChooseLinkedPage from '../inputs/ChooseLinkedPage.jsx';
import CurrentRoundTitle from '../multiplayer/CurrentRoundTitle.jsx';

function RoundStartedCard({ gameState, secondsLeft, beginPage, endPage, playerPath, onChange }) {
  const currentPage = playerPath.slice(-1)[0];

  return (
    <CardWrapper>
      {gameState && (
        <CurrentRoundTitle gameState={gameState} />
      )}
      <div className="flex items-center pb-4 space-x-4">
        <div className="flex-none w-1/3 text-center">
          <Timer secondsLeft={secondsLeft} />
        </div>
        <div className="w-2/3 text-center">
          <PlayerPathMinimal
            beginPage={beginPage}
            endPage={endPage}
            playerPath={playerPath}
          />
        </div>
      </div>

      <ChooseLinkedPage
        beginPage={beginPage}
        endPage={endPage}
        playerPath={playerPath}
        onChange={onChange}
      />

      {(currentPage && currentPage.id) === (endPage && endPage.id) && (
        <h2 className="text-lg text-center">
          Congrats, you did it!!
        </h2>
      )}
    </CardWrapper>
  );
}

export default RoundStartedCard;
