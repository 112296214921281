import { useContext, useEffect } from 'react';
import { SocketContext } from './App';

function useSocketOn (eventName, callback) {
  const socket = useContext(SocketContext);

  useEffect(() => {
    socket.on(eventName, callback);

    return () => {
      socket.off(eventName, callback);
    }
  }, [socket, eventName, callback])
}

export default useSocketOn;

