import React, { createContext, useRef } from 'react';
import io from 'socket.io-client';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppLayout from './AppLayout';
import { getToken, saveToken } from './auth.js';

export const SocketContext = createContext(undefined);

function SocketProvider ({ children }) {
  const socketRef = useRef(io(process.env.REACT_APP_BACKEND_URL, {
    query: {
      token: getToken()
    }
  }));

  socketRef.current.on('reconnect_attempt', () => {
    socketRef.current.io.opts.query = {
      token: getToken()
    };
  });

  socketRef.current.on('CONNECTION_WAS_SUCCESSFUL', (_id, token) => {
    saveToken(token);
  });

  return (
    <SocketContext.Provider value={socketRef.current}>
      <BrowserRouter>
        {children}
      </BrowserRouter>
    </SocketContext.Provider>
  );
}

function App () {
  return (
    <SocketProvider>
      <AppLayout />
    </SocketProvider>
  );
}

export default App;
