import React, { useState } from 'react';
import { Alert } from 'antd';
import useSocketOn from './useSocketOn.js';

function ReconnectingAlert() {
  const [isReconnecting, setIsReconnecting] = useState(false);
  useSocketOn('reconnect_attempt', () => setIsReconnecting(true));
  useSocketOn('connect', () => setIsReconnecting(false));

  if (!isReconnecting) return null;

  return (
    <Alert message="Connection was lost, attempting to reconnect..." type="warning" />
  );
}

export default ReconnectingAlert;
