import React, { useState } from 'react';
import { Form, Select, Button } from 'antd';
import debounce from 'lodash/debounce';

const { Option } = Select;

async function findPageByName(name) {
  if (!name) return [];
  name = encodeURIComponent(name);

  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/search-articles/${name}`);
  return await response.json();
}

async function getRandomPage() {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/random-article`);
  const jsonResponse = await response.json();

  return jsonResponse.title;
}

function ChooseWikiPage({ name, label, form, showInlinks }) {
  const [results, setResults] = useState([]);

  const handleSearch = debounce(async (value) => {
    const res = await findPageByName(value);

    if (showInlinks) {
      setResults(res.filter(r => r.inlink_count !== '0'));
    } else {
      setResults(res);
    }
  }, 300);

  const handleRandom = async () => {
    const randomPage = await getRandomPage();
    form.setFieldsValue({
      [name]: randomPage
    });
  };

  return (
    <Form.Item label={label} required>
      <Form.Item name={name} noStyle rules={[{ required: true }]}>
        <Select
          showSearch
          filterOption={false}
          onSearch={handleSearch}
        >
          {results.map((result, index) => (
            <Option key={index} value={result.title}>
              {result.title}
              <div className="text-xs italic">
                {showInlinks ? (
                  `${result.inlink_count} incoming links`
                ) : (
                  `${result.outlink_count} outgoing links`
                )}
              </div>
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Button onClick={handleRandom}>Random</Button>
    </Form.Item>
  );
}

export default ChooseWikiPage;
