import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { computeScoreV4, performSearch } from './searchUtils.js';

const { Search } = Input;

const KeyCode = {
  ENTER: 13,
  ARROW_UP: 38,
  ARROW_DOWN: 40
};

const DISABLED_KEYCODES_ON_INPUT = [
  KeyCode.ARROW_UP,
  KeyCode.ARROW_DOWN
];

async function findAllLinkedPages(page) {
  if (!page) return [];

  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/find-links/${page.id}`);
  const jsonResponse = await response.json();

  return jsonResponse.map(l => ({ id: l.to_article_id, title: l.to_title }));
}

const ChooseLinkedPage = ({ beginPage, endPage, playerPath, onChange }) => {
  const [loading, setLoading] = useState(true);
  const [linkedPages, setLinkedPages] = useState([]);
  const [filteredPages, setFilteredPages] = useState([]);
  const [error, setError] = useState('');
  const [inputText, setInputText] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);

  const currentPage = playerPath.length === 0 ? beginPage : playerPath.slice(-1)[0];

  const buttonRefs = filteredPages.map(() => React.createRef());

  const scrollToIndex = (index) => {
    if (!buttonRefs[index] || !buttonRefs[index].current) return;

    buttonRefs[index].current.scrollIntoView({ block: 'nearest' });
  };

  const handleSearch = (event) => {
    const searchText = event.target.value;
    setInputText(searchText);
    setActiveIndex(0);

    if (searchText === '') {
      setFilteredPages(linkedPages);
    } else {
      setFilteredPages(performSearch(linkedPages, searchText, computeScoreV4));
    }
  }

  const handleLinkChoice = (index) => {
    if (index < 0 || index >= filteredPages.length) return;

    onChange(filteredPages[index])
    setInputText('');
  };

  const handleKeyDown = (event) => {
    const key = event.keyCode;
    let offset = 0;

    if (DISABLED_KEYCODES_ON_INPUT.includes(key)) event.preventDefault();

    if (key === KeyCode.ENTER) {
      handleLinkChoice(activeIndex);
      setActiveIndex(0);
      return;
    }

    if (key === KeyCode.ARROW_UP) offset = -1;
    if (key === KeyCode.ARROW_DOWN) offset = 1;

    if (offset === 0) return;

    setActiveIndex(prevIndex => {
      let newIndex = prevIndex + offset;

      if (newIndex < 0) newIndex = 0;
      if (newIndex > filteredPages.length - 1) newIndex = filteredPages.length - 1;
      scrollToIndex(newIndex);

      return newIndex;
    });
  };

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    setLinkedPages([]);
    setFilteredPages([]);
    setError('');

    findAllLinkedPages(currentPage).then(pages => {
      if (!mounted) return;

      setLinkedPages(pages);
      setFilteredPages(pages);
      setLoading(false);
    }).catch(e => {
      if (!mounted) return;

      console.error(e);
      setError('Could not fetch linked pages for Page: ' + currentPage);
    });

    return () => {
      mounted = false;
    };
  }, [currentPage]);

  if ((currentPage && currentPage.id) === (endPage && endPage.id)) {
    return null;
  }

  return (
    <>
      <span className="text-red-500">
        {error}
      </span>

      <div className="pb-4">
        <Search
          autoFocus
          value={inputText}
          onChange={handleSearch}
          placeholder="Filter links"
          onKeyDown={handleKeyDown}
          onFocus={() => setActiveIndex(0)}
          onBlur={() => setActiveIndex(-1)}
        />
      </div>

      <div className="border-t border-b border-gray-400 divide-y divide-gray-400">
        {loading && (
          <div className="p-3 text-center">
            Loading...
          </div>
        )}
        {!loading && filteredPages.length === 0 && (
          <div className="p-3 text-center">
            There are no matching links
          </div>
        )}
        {filteredPages.map((page, index) =>
          <button
            key={page.title}
            ref={buttonRefs[index]}
            className={`block w-full p-3 focus:bg-blue-100 lg:hover:bg-blue-100 ${index === activeIndex ? 'bg-gray-200' : ''}`}
            onClick={() => handleLinkChoice(index)}
          >
            {page.title}
          </button>
        )}
      </div>
    </>
  )
};

export default React.memo(ChooseLinkedPage, (prevProps, nextProps) => {
  return (prevProps.beginPage && prevProps.beginPage.id) === (nextProps.beginPage && nextProps.beginPage.id) &&
    (prevProps.endPage && prevProps.endPage.id) === (nextProps.endPage && nextProps.endPage.id) &&
    prevProps.playerPath.length === nextProps.playerPath.length;
});
