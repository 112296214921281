import React from 'react';
import CardWrapper from '../shared/CardWrapper.jsx';

function PlayerListing({ gameState }) {
  if (!gameState.players) return null;

  return (
    <div className="md:w-1/2 p-2">
      <CardWrapper>
        <h2 className="text-lg">{`Players (${Object.keys(gameState.players).length})`}</h2>

        <ul>
          {Object.entries(gameState.players).map(([id, p], index) => (
            <li key={index}>{`${p.name}${gameState.hostPlayerId === id ? ' (HOST)' : ''}`}</li>
          ))}
        </ul>
      </CardWrapper>
    </div>
  );
}

export default PlayerListing;
