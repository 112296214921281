import React from 'react';
import Timer from '../../shared/Timer.jsx';
import OverallLeaderboard from '../OverallLeaderboard.jsx';
import PreviousRound from '../PreviousRound.jsx';
import PlayerPaths from '../PlayerPaths.jsx';
import CurrentRoundTitle from '../CurrentRoundTitle.jsx';

function RoundStartedAsMaster({ gameState }) {
  const { currentRound, players } = gameState;
  const { secondsLeft } = currentRound;

  return (
    <>
      <div className="md:w-full p-2">
        <div className="shadow-md bg-white p-6 text-center">
          <CurrentRoundTitle gameState={gameState} />
          <Timer secondsLeft={secondsLeft} />
        </div>
      </div>

      <OverallLeaderboard gameState={gameState} />
      <PreviousRound gameState={gameState} />

      <PlayerPaths players={players} round={ currentRound } />
    </>
  );
}

export default RoundStartedAsMaster;
