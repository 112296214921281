import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import produce from 'immer';
import { SocketContext } from '../App';
import useSocketOn from '../useSocketOn';
import GameCreated from './GameCreated.jsx';
import NewRound from './NewRound.jsx';
import RoundStarted from './RoundStarted.jsx';
import CardWrapper from '../shared/CardWrapper.jsx';
import GameEnded from './GameEnded.jsx';

function MultiplayerGame ({ myId }) {
  const { gameId } = useParams();
  const socket = useContext(SocketContext);
  const [myStatus, setMyStatus] = useState('JOINING');
  const [gameState, dispatch] = useReducer((state, action) => {
    return produce(state, draft => {
      switch (action.type) {
        case 'GAME_STATE_CHANGED':
          return action.gameState;
        default:
          throw new Error('unknown action type: ' + action.type());
      }
    });
  }, {});

  useSocketOn('CONNECTION_WAS_SUCCESSFUL', () => {
    // in case of WebSocket reconnect
    socket.emit('JOIN_GAME', gameId);
  });
  useSocketOn('GAME_JOINED', gameState => {
    setMyStatus('JOINED');
    dispatch({ type: 'GAME_STATE_CHANGED', gameState })
  });
  useSocketOn('GAME_STATE_CHANGED', gameState => dispatch({ type: 'GAME_STATE_CHANGED', gameState }))
  useSocketOn('ERROR_UNKNOWN_GAME', () => setMyStatus('GAME_NOT_FOUND'));
  useSocketOn('ERROR_GAME_ALREADY_STARTED', () => setMyStatus('ERROR_GAME_ALREADY_STARTED'));

  useEffect(() => {
    socket.emit('JOIN_GAME', gameId);

    return () => {
      socket.emit('LEAVE_GAME');
    }
  }, [socket, gameId]);

  if (myStatus === 'JOINING') {
    return <h2>Joining game...</h2>;
  }

  if (['GAME_NOT_FOUND', 'ERROR_GAME_ALREADY_STARTED'].includes(myStatus)) {
    return (
      <div className="max-w-lg mx-auto">
        <CardWrapper>
          <div className="text-center">
            <h2 className="text-lg">
              {myStatus === 'ERROR_GAME_ALREADY_STARTED' ? (
                'Game already started'
              ) : (
                'Game Not Found'
              )}
            </h2>
            <span>Go back to <Link to="/">Homepage</Link>.</span>
          </div>
        </CardWrapper>
      </div>
    )
  }

  const gameStatusComponentMap = {
    'GAME_CREATED': <GameCreated gameState={gameState} myId={myId} />,
    'NEW_ROUND': <NewRound gameState={gameState} myId={myId} />,
    'ROUND_STARTED': <RoundStarted gameState={gameState} myId={myId} dispatch={dispatch} />,
    'GAME_ENDED': <GameEnded gameState={gameState} />
  };

  return (
    <>
      <div className="md:flex md:flex-wrap">
        {gameStatusComponentMap[gameState.status] || `Unknown game status: ${gameState.status}`}
      </div>

      {/*<h2>My playerId: {myId}</h2>*/}

      {/*<div>My Status: {myStatus}</div>*/}

      {/*<h3>Game state</h3>*/}
      {/*<pre>{JSON.stringify(gameState, undefined, 2)}</pre>*/}
    </>
  )
}

export default MultiplayerGame;
