import React from 'react';
import NewRoundAsMaster from './new_round/NewRoundAsMaster.jsx';
import NewRoundAsSlave from './new_round/NewRoundAsSlave.jsx';

function NewRound (props) {
  const { gameState, myId } = props;

  if (gameState.currentRound.masterPlayerId === myId) {
    return <NewRoundAsMaster {...props} />
  } else {
    return <NewRoundAsSlave {...props} />
  }
}

export default NewRound;
