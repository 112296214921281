import React, { useContext } from 'react';
import { SocketContext } from '../../App.jsx';
import RoundStartedCard from '../../shared/RoundStartedCard.jsx';
import OverallLeaderboard from '../OverallLeaderboard.jsx';
import PreviousRound from '../PreviousRound.jsx';
import PlayerPaths from '../PlayerPaths.jsx';

function RoundStartedAsSlave({ gameState, myId }) {
  const socket = useContext(SocketContext);
  const { beginPage, endPage, secondsLeft, playerPaths } = gameState.currentRound;
  const myPath = playerPaths[myId];
  const currentPage = myPath.slice(-1)[0];

  const handleChangeCurrentPage = (newArticle) => {
    socket.emit('PLAYER_CHANGED_CURRENT_PAGE', newArticle)
  };

  return (
    <>
      <div className="md:w-full p-2">
        <div className="max-w-lg mx-auto">
          <RoundStartedCard
            gameState={gameState}
            secondsLeft={secondsLeft}
            beginPage={beginPage}
            endPage={endPage}
            playerPath={myPath}
            onChange={handleChangeCurrentPage}
          />
        </div>
      </div>

      {(currentPage && currentPage.id) === (endPage && endPage.id) && (
        <>
          <OverallLeaderboard gameState={gameState} />
          <PreviousRound gameState={gameState} />

          <PlayerPaths players={gameState.players} round={ gameState.currentRound } />
        </>
      )}
    </>
  );
}

export default RoundStartedAsSlave;
