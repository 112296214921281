import React, { useState } from 'react';
import { Progress } from 'antd';

// TODO: use currentRound.roundTime instead of initialSeconds
function Timer ({ secondsLeft }) {
  const [initialSeconds] = useState(secondsLeft);
  const minutes = Math.floor(secondsLeft / 60);
  const seconds = secondsLeft % 60;

  return (
    <Progress
      type="circle"
      percent={secondsLeft * 100 / initialSeconds}
      status={secondsLeft > 10 ? 'normal' : 'exception'}
      format={() => `${minutes}:${seconds.toString().padStart(2, '0')}`}
      width={100}
    />
  );
}

export default Timer;
