import React from 'react';

function PlayerPath({ beginPage, endPage, playerPath }) {
  const currentPage = playerPath.slice(-1)[0];

  return (
    <>
      <div className={playerPath.length === 0 ? 'font-bold' : ''}>{beginPage && beginPage.title}</div>
      {playerPath.map((page, i) => (
        <React.Fragment key={i}>
          <div className="text-xl">↓</div>
          {i === playerPath.length - 1 ? (
            <div className="font-bold">{page.title}</div>
          ) : (
            <div>{page.title}</div>
          )}
        </React.Fragment>
      ))}
      {(currentPage && currentPage.id) !== (endPage && endPage.id) && (
        <>
          <div className="text-xl">↓</div>
          <div className="text-xl">…</div>
          <div className="text-xl">↓</div>
          <div>{endPage && endPage.title}</div>
        </>
      )}
    </>
  );
}

export default PlayerPath;
