import React from 'react';
import Scoreboard from './Scoreboard.jsx';

function OverallLeaderboard({ gameState }) {
  return (
    <div className="md:w-1/2 p-2">
      <Scoreboard
        title="Leaderboard"
        players={Object.entries(gameState.players).map(([id, p]) => (
          { name: p.name, score: gameState.overallScoreboard[id] }
        ))}
      />
    </div>
  );
}

export default OverallLeaderboard;
