import React from 'react';
import CardWrapper from '../shared/CardWrapper.jsx';

function formatTime(totalSeconds) {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
}

function formatPlayerTime(secondsLeft, roundTime) {
  if (secondsLeft === 0) return 'N/A';

  return formatTime(roundTime - secondsLeft);
}

function PreviousRound({ gameState }) {
  const { players, prevRound } = gameState

  if (!prevRound) return null;

  return (
    <div className="md:w-1/2 p-2">
      <CardWrapper>
        <h2 className="text-lg text-center uppercase">Previous Round</h2>

        <table className="w-full">
          <thead>
            <tr>
              <th />
              <th />
              <th className="pr-4">Score</th>
              <th className="pr-4">Time</th>
              <th className="pr-4">Steps</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="pr-4">C</td>
              <td className="w-full pr-4">{players[prevRound.masterPlayerId].name}</td>
              <td className="pr-4">{prevRound.playerScores[prevRound.masterPlayerId]}</td>
              <td className="pr-4" />
              <td className="pr-4" />
            </tr>
            {prevRound.ranking.map((id, index) => (
              <tr key={index}>
                <td className="pr-4">#{index + 1}</td>
                <td className="w-full pr-4">{players[id].name}</td>
                <td className="pr-4">{prevRound.playerScores[id]}</td>
                <td className="pr-4">{formatPlayerTime(prevRound.playerStats[id].secondsLeft, prevRound.roundTime)}</td>
                <td className="pr-4">{prevRound.playerPaths[id].length}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </CardWrapper>
    </div>
  );
}

export default PreviousRound;
