import React, { useContext, useState } from 'react';
import { Input, Button } from 'antd';
import { SocketContext } from '../App.jsx';
import useSocketOn from '../useSocketOn.js';
import CardWrapper from '../shared/CardWrapper.jsx';
import PlayerListing from './PlayerListing.jsx';

function GameCreated ({ gameState, myId, dispatch }) {
  const me = gameState.players[myId]
  const socket = useContext(SocketContext);
  const [myName, setMyName] = useState(me && me.name);
  const [error, setError] = useState('');

  useSocketOn('ERROR_NAME_ALREADY_TAKEN', () => {
    setError('Name is already taken.');
  });

  const handleChangeName = (e) => {
    e.preventDefault();

    setError('');
    socket.emit('CHANGE_MY_NAME', myName)
  };

  return (
    <>
      <div className="md:w-1/2 p-2">
        <CardWrapper>
          <h1 className="text-lg pb-4">
            Waiting for players
          </h1>

          <div className="pb-2">
            <form action="" onSubmit={handleChangeName} className="flex w-full">
              <Input maxLength="20" value={myName} onChange={event => setMyName(event.target.value)} />
              <Button type="primary" htmlType="submit">Change name</Button>
              <span className="text-red-500">{error}</span>
            </form>
          </div>

          <div className="pb-2">
            <Input defaultValue={window.location.href} />
          </div>

          {gameState.hostPlayerId === myId ? (
            <Button
              type="primary"
              onClick={() => {
                socket.emit('START_GAME');
              }}
            >
              Start Game
            </Button>
          ) : (
            <div className="pt-2">
              {`Only Host player (${gameState.players[gameState.hostPlayerId].name}) can start the game.`}
            </div>
          )}
        </CardWrapper>
      </div>

      <PlayerListing gameState={gameState} />
    </>
  )
}

export default GameCreated;
