import React, { useState } from 'react';
import { Form, Button, Select } from 'antd';
import ChooseWikiPage from '../inputs/ChooseWikiPage.jsx';

const { Option } = Select;

function NewRoundForm ({ onSubmit }) {
  const [error, setError] = useState('');
  const [form] = Form.useForm();

  const handleStartRound = (values) => {
    const { beginPage, endPage, roundTime } = values;
    setError('');

    if (beginPage === endPage) {
      setError('End page needs to be different from Begin page');
      return;
    }

    onSubmit({ beginPage, endPage, roundTime });
  };

  return (
    <Form
      form={form}
      name="new-round"
      initialValues={{ roundTime: 'TWO_MINUTES' }}
      onFinish={handleStartRound}
    >
      <ChooseWikiPage name="beginPage" label="Begin page" form={form} showInlinks={false} />
      <ChooseWikiPage name="endPage" label="End page" form={form} showInlinks={true} />
      <Form.Item name="roundTime" label="Time limit" rules={[{ required: true }]}>
        <Select>
          <Option value="HALF_MINUTE">0:30</Option>
          <Option value="ONE_MINUTE">1:00</Option>
          <Option value="MINUTE_AND_HALF">1:30</Option>
          <Option value="TWO_MINUTES">2:00</Option>
          <Option value="TWO_MINUTES_AND_HALF">2:30</Option>
          <Option value="THREE_MINUTES">3:00</Option>
        </Select>
      </Form.Item>

      <div>
        <Button type="primary" htmlType="submit">Start Round</Button>
      </div>
      <span className="text-red-500">
        {error}
      </span>
    </Form>
  );
}

export default NewRoundForm;
