import React, { useContext, useEffect } from 'react';
import { notification } from 'antd';
import { SocketContext } from '../../App.jsx';
import NewRoundForm from '../../shared/NewRoundForm.jsx';
import CardWrapper from '../../shared/CardWrapper.jsx';
import PreviousRound from '../PreviousRound.jsx';
import OverallLeaderboard from '../OverallLeaderboard.jsx';
import PlayerPaths from '../PlayerPaths.jsx';
import CurrentRoundTitle from '../CurrentRoundTitle.jsx';

function NewRoundAsMaster ({ gameState }) {
  const socket = useContext(SocketContext);
  useEffect(() => {
    setTimeout(() => {
      notification['info']({
        message: 'You are the Challenger!'
      });
    }, 700);
  }, [])

  return (
    <>
      <div className="md:w-full p-2">
        <CardWrapper>
          <CurrentRoundTitle gameState={gameState} />
          <h2 className="text-lg text-center">You are the Challenger!</h2>

          <NewRoundForm onSubmit={values => socket.emit('START_ROUND', values)} />
        </CardWrapper>
      </div>

      <OverallLeaderboard gameState={gameState} />
      <PreviousRound gameState={gameState} />

      <PlayerPaths players={gameState.players} round={gameState.prevRound} />
    </>
  );
}

export default NewRoundAsMaster;
