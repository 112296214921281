import React from 'react';
import RoundStartedAsMaster from './round_started/RoundStartedAsMaster.jsx';
import RoundStartedAsSlave from './round_started/RoundStartedAsSlave.jsx';

function RoundStarted (props) {
  const { gameState, myId } = props;

  if (gameState.currentRound.masterPlayerId === myId) {
    return <RoundStartedAsMaster {...props} />
  } else {
    return <RoundStartedAsSlave {...props} />
  }
}

export default RoundStarted;
