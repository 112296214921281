import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Button } from 'antd';
import { SocketContext } from './App';
import useSocketOn from './useSocketOn.js';

function Home () {
  const socket = useContext(SocketContext);
  const history = useHistory();
  useSocketOn('GAME_CREATED', ({ id }) => history.push(`/${id}`));

  const handleStartSingleplayerGame = () => {
    history.push('/singleplayer');
  };

  const handleCreateMultiplayerGame = () => {
    socket.emit('CREATE_GAME');
  };

  const handleJoinMultiplayerGame = (e) => {
    e.preventDefault();

    history.push(`/${e.target.gameId.value}`);
  };

  return (
    <div className="max-w-lg mx-auto sm:flex space-y-4 sm:space-y-0 shadow-md bg-white p-6">
      <div className="sm:w-1/2 text-center">
        <h2 className="text-lg">Single Player</h2>

        <Button type="primary" onClick={handleStartSingleplayerGame}>
          Start Game
        </Button>
      </div>
      <div className="sm:w-1/2 text-center">
        <h2 className="text-lg">Multiplayer</h2>

        <Button type="primary" onClick={handleCreateMultiplayerGame}>
          Create New Game
        </Button>

        <div className="pt-2">
          <form action="" onSubmit={handleJoinMultiplayerGame} className="flex w-56 mx-auto">
            <Input placeholder="MultiplayerGame ID" name="gameId" />
            <Button type="primary" htmlType="submit">JOIN</Button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Home;
