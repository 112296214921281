export function performSearch(pages, searchText, scoreFunction) {
  return pages.map(page => {
    return {
      id: page.id,
      title: page.title,
      score: scoreFunction(page.title, searchText)
    };
  }).filter(p => p.score >= 0)
    .sort((p1, p2) => {
      if (p1.score < p2.score) return -1;
      if (p1.score > p2.score) return 1;

      return p1.title.localeCompare(p2.title);
    })
    .map(p => ({ id: p.id, title: p.title }));
}

export function computeBasicScore(pageName, searchTerm)
{
  return pageName.toLowerCase().indexOf(searchTerm.toLowerCase());
}

export function computeScoreV2(pageName, searchTerm)
{
  if (pageName.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)
  {
    // exact match
    if (pageName.length === searchTerm.length)
    {
      return 0;
    }
    // match, but not exact (add 1 to differentiate from exact match)
    else
    {
      return pageName.toLowerCase().indexOf(searchTerm.toLowerCase()) + 1;
    }
  }
  else
  {
    return -100;
  }
}

export function computeScoreV3(pageName, searchTerm)
{
  // whole search term
  if (pageName.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)
  {
    // exact match
    if (pageName.length === searchTerm.length)
    {
      return 0;
    }
    // match, but not exact (add 1 to differentiate from exact match)
    else
    {
      return pageName.toLowerCase().indexOf(searchTerm.toLowerCase()) + 1;
    }
  }
  else
  {
    // extract words from search term
    let searchWords = searchTerm.split(" ");

    for (let word of searchWords)
    {
      if (pageName.toLowerCase().indexOf(word.toLowerCase()) >= 0)
      {
        let wordScore;
        // exact match for word
        if (pageName.length === word.length)
        {
          wordScore = 0;
        }
        // match, but not exact (add 1 to differentiate from exact match)
        else
        {
          wordScore = pageName.toLowerCase().indexOf(word.toLowerCase()) + 1;
        }

        // we find a match with only a word of the search term, thus we penalize with a +100 score
        return 100 + wordScore;
      }
    }
    // no match
    return -100;
  }
}

export function computeScoreV4(pageName, searchTerm) {
  pageName = pageName.toLowerCase();
  searchTerm = searchTerm.toLowerCase();

  // no match
  if (pageName.indexOf(searchTerm) === -1) return -1;

  // exact match entire page name
  if (pageName === searchTerm) return 0;

  const pageNameWords = pageName.split(/[^a-z0-9]/).filter(s => s !== '');

  // exact match first word
  if (pageNameWords[0] === searchTerm) return 1;

  const index = pageName.indexOf(searchTerm);

  // start of page name
  if (index === 0) return 2;

  // exact match another word
  for (let word of pageNameWords) {
    if (word === searchTerm) return 3;
  }

  // start of another word (but before end of the word)
  for (let word of pageNameWords) {
    if (word.indexOf(searchTerm) === 0) return 4;
  }

  // match starting in the middle of a word
  return 5;
}
